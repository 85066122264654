/*!
 * Created by Santiago Prada
 * Copyright © 2016 IBM. All rights reserved.
 */


var ExpandableSections = function(sections, onOpen, onClose) {

  that = this;
  that.onOpen = onOpen;
  that.onClose = onClose;

  this.sections = sections;
  this.allExpandableButtons = $(".expandable-button");
  this.activeButtonClass = "expandable-button-active";

  $.each(this.sections, function(index, sec){
    $(sec.button).on("click", function(e){
      e.preventDefault();
      that.shouldOpenSection(this, $(sec.section));
    });
  })

  this.openSection = function(button, sectionToOpen){

    if($(".expandable-section").length == 1) {
       sectionToOpen.slideDown(200, function(){
          if(that.onOpen)
            that.onOpen();

          document.querySelector("#"+button.id).scrollIntoView({ behavior: 'smooth' });

       });
       return;
    }

    //Close any open sections
    $(".expandable-section").not(sectionToOpen).slideUp(200,function(){
      sectionToOpen.slideDown(200, function(){
        if(that.onOpen)
          that.onOpen();

        document.querySelector("#"+button.id).scrollIntoView({ behavior: 'smooth' });
      });
    });
  };

  this.shouldOpenSection = function(button, sectionToOpen){
    if ($(button).hasClass(that.activeButtonClass)){
      sectionToOpen.slideUp();
      if(that.onClose)
        that.onClose();
      that.allExpandableButtons.removeClass(that.activeButtonClass);
      return
    }else {
      that.allExpandableButtons.removeClass(that.activeButtonClass);
      $(button).addClass(that.activeButtonClass);
      that.openSection(button, sectionToOpen);
    };
  };

  $(".close-expandable-section").on("click", function(e){
    e.preventDefault();
    $(this).parent().slideUp();
    that.allExpandableButtons.removeClass(that.activeButtonClass);
    if(that.onClose)
        that.onClose();
  });

  self = this;

  this.organizeButtons = function() {
      var container = $(".expandable-buttons-container");
      var width = container.width();

      var exps = $(".expandable-buttons");

      exps.each(function(a) {
        var exp = $(exps[a]);
        var count = exp.find(".expandable-button").length;
        if(!exp.hasClass("expandable-buttons-"+count))
          exp.addClass("expandable-buttons-"+count);
      });
  };

  $(function(){
      self.organizeButtons();
  });

}

